(function($, window, document, undefined) {
 
    'use strict';
 
    var defaults = {
        Clickjo: true
    };
 
    var Clickjo = function(element) {
 
        // You can access all lightgallery variables and functions like this.
        this.core = $(element).data('lightGallery');
 
        this.$el = $(element);
        this.core.s = $.extend({}, defaults, this.core.s)
 
        this.init();
 
        return this;
    }
 
    Clickjo.prototype.init = function() {
		var _this = this;
		var slideIndex = 0;
		var clickJoIcons = 
			  '<span id="clg-phone" class="fa fa-phone lg-icon" ></span>'
    		+ '<a id="clg-download" class="lg-download lg-icon" target="_blank" download></a>'
    		+ '<a id="clg-user-link" href="#" class="fa fa-user lg-icon"></a>'
    		+ '<span id="clg-fav" class="fa fa-heart lg-icon" ></span>'
    		+ '<a id="clg-location-url" target="_blank" href="" class="fa fa-map-o lg-icon"></a>'
    		+ '<span id="clg-report-icon" class="fa fa-warning lg-icon"></span>'
    		+ '<span id="clg-comment-icon" class="fa fa-comment lg-icon"></span>';

		this.core.$outer.find('.lg-toolbar').append(clickJoIcons);
    };
 
    /**
    * Destroy function must be defined.
    * lightgallery will automatically call your module destroy function 
    * before destroying the gallery
    */
    Clickjo.prototype.destroy = function() {
 
    }
 
    // Attach your module with lightgallery
    $.fn.lightGallery.modules.Clickjo = Clickjo;
 
 
})(jQuery, window, document);