(function() {

    'use strict';

    var defaults = {
        clickjoHash: true
    };

    var ClickjoHash = function(element) {

        this.core = $(element).data('lightGallery');

        this.core.s = $.extend({}, defaults, this.core.s);

        if (this.core.s.clickjoHash) {
            this.oldClickjoHash = window.location.clickjoHash;
            this.init();
        }

        return this;
    };

    ClickjoHash.prototype.init = function() {
        var _this = this;
        var _clickjoHash;

        // Change clickjoHash value on after each slide transition
        _this.core.$el.on('onAfterSlide.lg.tm', function(event, prevIndex, index) {
            if (history.replaceState) {
                history.replaceState(null, null, window.location.pathname + window.location.search + '#lg=' + _this.core.s.galleryId + '&slide=' + index);
            } else {
                window.location.clickjoHash = 'lg=' + _this.core.s.galleryId + '&slide=' + index;
            }
        });

        // Listen clickjoHash change and change the slide according to slide value
        $(window).on('clickjoHashchange.lg.clickjoHash', function() {
            _clickjoHash = window.location.clickjoHash;
            var _idx = parseInt(_clickjoHash.split('&slide=')[1], 10);

            // it galleryId doesn't exist in the url close the gallery
            if ((_clickjoHash.indexOf('lg=' + _this.core.s.galleryId) > -1)) {
                _this.core.slide(_idx, false, false);
            } else if (_this.core.lGalleryOn) {
                _this.core.destroy();
            }

        });
    };

    ClickjoHash.prototype.destroy = function() {

        if (!this.core.s.clickjoHash) {
            return;
        }

        // Reset to old clickjoHash value
        if (this.oldClickjoHash && this.oldClickjoHash.indexOf('lg=' + this.core.s.galleryId) < 0) {
            if (history.replaceState) {
                history.replaceState(null, null, this.oldClickjoHash);
            } else {
                window.location.clickjoHash = this.oldClickjoHash;
            }
        } else {
            if (history.replaceState) {
                history.replaceState(null, document.title, window.location.pathname + window.location.search);
            } else {
                window.location.clickjoHash = '';
            }
        }

        this.core.$el.off('.lg.clickjoHash');

    };

    $.fn.lightGallery.modules.clickjoHash = ClickjoHash;

})();
